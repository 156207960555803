html {
	--section-background-color: linear-gradient(
		to bottom left,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 240, 0.8)
	);

	--image-gradient: linear-gradient(
		to bottom left,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 240, 0.8)
	);

	--imp-text-color: #19181a;
}

button:focus {
	box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: -999;
	background-color: #0d0d0d;
	background-image: url(../Assets/pre.svg);
	background-repeat: no-repeat;
	background-position: center;
}

#preloader-none {
	opacity: 0;
}

#no-scroll {
	overflow: hidden;
	height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #9a979e;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(215, 212, 217, 0.959);
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(217, 211, 217, 0.911);
	border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
	background-color: #11111aa9 !important;
	transition: all 0.3s ease-out 0s !important;
	box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
	backdrop-filter: blur(15px) !important;
}

.navbar {
	position: fixed !important;
	transition: all 0.3s ease-out 0s !important;
	padding: 0.3rem 2rem !important;
	font-size: 1.2rem !important;
}

.navbar-toggler {
	position: relative !important;
	background-color: transparent !important;
	border-color: transparent !important;
}

.navbar-toggler span {
	display: block !important;
	background-color: #d2ced4 !important;
	height: 4px !important;
	width: 27px !important;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	transform: rotate(0deg) !important;
	left: 0 !important;
	opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
	outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
	transition: transform 0.35s ease-in-out !important;
	transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(135deg) !important;
	opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
	height: 12px !important;
	visibility: hidden !important;
	background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(-135deg) !important;
	opacity: 0.9 !important;
}

@media (max-width: 767px) {
	.navbar {
		padding: 1rem 2rem !important;
		font-size: 1.4rem !important;
		background-color: #131317 !important;
	}
	.navbar-nav .nav-item a::after {
		display: none !important;
	}
}
.navbar-brand {
	color: rgb(250, 250, 250) !important;
}

.logo {
	height: 1.4em !important;
	width: 2.5em !important;
}

.navbar-nav .nav-link {
	color: #f3f3f3 !important;
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.nav-link {
	padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
	.nav-link {
		padding: 0.7rem 1rem !important;
	}
}

.navbar-nav .nav-item {
	position: relative;
	margin-left: 20px;
}

.navbar-nav .nav-item a {
	font-weight: 400;
	transition: all 0.3s ease-out 0s;
	position: relative;
	z-index: 1;
}

.navbar-nav .nav-item a::after {
	content: "";
	position: relative;
	display: block;
	height: 5px;
	width: 0;
	border-radius: 16px;
	background: #bebbbf;
	bottom: 1px;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
	width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

.main {
	width: 100%;
	height: 100vh;
}

video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.video-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
  }

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
}

.home-header {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: rgb(255, 255, 255);
}

.home-section {
	position: relative;
	z-index: -1;
	background-position: top center;
	background-repeat: no-repeat;
	padding-bottom: 30px !important;
	padding-top: 30px !important;
}

.home-content {
	color: whitesmoke;
	position: absolute;
	width: 100%;
	height: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.heading {
	font-size: 2.4em !important;
	padding-left: 50px !important;
}

.heading-name {
	font-size: 2.5em !important;
	padding-left: 45px !important;
}

.main-name {
	color: #e8e8e8;
}
/* #bababa */

.Typewriter__wrapper {
	font-size: 1.6em !important;
	color: #8a8a8a !important;
	font-weight: 600 !important;
	justify-content: center;
	align-items: center;
}
.Typewriter__cursor {
	font-size: 1.8em !important;
	color: #8a8a8a !important;
	justify-content: center;
	align-items: center;
}

@media (max-width: 767px) {
	.Typewriter__wrapper {
		font-size: 1.2em !important;
		font-weight: 400 !important;
		position: relative !important;
		justify-content: center;
		align-items: center;
	}
	.Typewriter__cursor {
		display: none !important;
		justify-content: center;
		align-items: center;
	}
}

.home-about-section {
	position: relative;
	padding-bottom: 70px !important;
	padding-top: 70px !important;
}

.home-about-description {
	color: #f3f3f3 !important;
	padding-top: 100px !important;
	padding-bottom: 20px !important;
	text-align: center;
}

.home-about-body {
	padding-top: 50px;
	font-size: 1.2em !important;
	text-align: left;
}

.home-about-social {
	text-align: center !important;
	padding-top: 25px;
	color: #f3f3f3 !important;
}

.home-about-social-links {
	justify-content: center !important;
	padding-top: 15px !important;
	position: relative !important;
	padding-inline-start: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.home-social-icons {
	position: relative !important;
	display: inline-block !important;
	width: 40px !important;
	height: 40px !important;
	text-align: center !important;
	font-size: 1.2em !important;
	line-height: 2em !important;
	background: rgba(255, 255, 255, 0.972) !important;
	border-radius: 50% !important;
	transition: 0.5s !important;
}

.home-social-icons::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: #fef8ff;
	transition: 0.5s;
	transform: scale(0.9);
	z-index: -1;
}

.home-social-icons:hover::before {
	transform: scale(1.1);
	box-shadow: 0 0 15px #e4dfe5;
}

.home-social-icons:hover {
	color: #e4dfe5;
	box-shadow: 0 0 5px #e4dfe5;
	text-shadow: 0 0 2px #e4dfe5;
}

.social-icons {
	display: inline-block !important;
	padding-right: 15px;
	padding-left: 15px;
}

.icon-colour {
	color: #8a8a8a !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
	position: fixed !important;
	bottom: 0 !important;
	padding-top: 3px !important;
	padding-bottom: 1px !important ;
	background-color: rgba(0, 0, 0, 0.3);
}
.footer-copywright {
	text-align: center !important;
}

.footer-body {
	z-index: 1;
	text-align: center !important;
}

@media (max-width: 767px) {
	.footer-copywright {
		text-align: center !important;
	}

	.footer-body {
		text-align: center !important;
	}
}

.footer h3 {
	font-size: 1em;
	color: white !important;
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
}
.footer-icons {
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
	padding: 0 !important;
}

.blockquote-footer {
	color: #fffff0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
	position: relative !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
}

.project-card {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;
}

.project-card-view {
	box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
	color: f3f3f3 !important;
	background-color: transparent !important;
	opacity: 0.9 !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
}
.project-card-view:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;
}

.blog-card-view {
	background-color: transparent !important;
	box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
	color: white !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
}

.blog-link {
	color: white !important;
	text-decoration: none !important;
}

.blog-link:hover {
	cursor: pointer !important;
}

.blog-card-view:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
	padding: 20px !important;
	opacity: 0.8 !important;
	border-radius: 10px !important;
}

.blog-img {
	padding: 0px !important;
	opacity: 0.8 !important;
	border-radius: 0px !important;
}

.btn-primary {
	color: #fff !important;
	background-color: #623686 !important;
	border-color: #623686 !important;
}

.btn-primary:hover {
	color: #fff !important;
	background-color: #6d20c5d7 !important;
	border-color: #6d20c5d7 !important;
}
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}
.project-heading {
	color: #8a8a8a !important;
	font-size: 2.3em !important;
	font-weight: 500 !important;
	padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
	position: absolute !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	height: 100% !important;
	background-image: var(--section-background-color) !important;
	color: #f3f3f3 !important;
}

.tech-icons:hover {
	transform: scale(1.05) !important;
	overflow: hidden !important;
	border: 2.2px solid rgba(255, 255, 240, 0.4) !important;
}
.tech-icon-images {
	padding: 20px !important;
	line-height: 1.6 !important;
}

.quote-card-view {
	border: none !important;
	color: #f3f3f3 !important;
	background-color: transparent !important;
}

.about-activity {
	list-style: none !important;
	text-align: left !important;
	padding-left: 1px !important;
}

@media (max-width: 767px) {
	.about-img {
		padding-top: 0 !important;
	}
}

/* --------- */
/* Contact */
/* --------- */

.contact-section {
	position: relative !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
	color: #f3f3f3 !important;
	box-sizing: border-box;
}

.form-box {
	padding-top: 30px;
	padding-bottom: 30px;
	display: block;
	background-color: rgba(255, 255, 240, 0.2);
	box-shadow: 2px 2px 2px 2px rgb(221, 221, 221, 0.2);
	border-radius: 4px;
	font-family: helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif, !important;
}

input {
	padding: 10px 30px;
	background-color: rgba(225, 222, 222, 0.2) !important;
	color: #8a8a8a;
	border-width: 1px;
	border-color: #8a8a8a;
	border-style: solid;
	margin-right: 5px;
	cursor: pointer;
	border-radius: 5px;
}

textarea {
	padding: 20px 30px;
	background-color: rgba(225, 222, 222, 0.2) !important;
	color: #8a8a8a;
	border-width: 1px;
	border-color: #8a8a8a;
	border-style: solid;
	margin-right: 5px;
	cursor: pointer;
	border-radius: 5px;
}

.form-button {
	padding: 10px 20px;
	color: #8a8a8a;
	border-color: #8a8a8a;
	background-color: inherit;
	box-shadow: 1px 1px 1px 1px rgba(237, 236, 234, 0.2);
	border-radius: 5px;
	border-width: 2px;
	margin-right: 5px;
	cursor: pointer;
}
/* background-color: rgba(138, 138, 138, 0.4); */


/* --------- */
/* MISC */
/* --------- */

.img-clients {
	float: left;
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.client-section {
	position: relative !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	height: 100% !important;
	background-image: var(--section-background-color) !important;
	color: #f3f3f3 !important;
}

.footer-div {
	margin: 0 !important;
	padding: 0 !important;
	width: 100% !important;
	height: 100% !important;
	padding-top: 2em !important;
	padding-bottom: 2em !important;
}