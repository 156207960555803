.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: rgba(255, 255, 240, 0.6);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@import url("https://db.onlinewebfonts.com/c/f7d1b2995a6dff7abcda304d226aa67d?family=Helvetica+W01+Bold");
.tab-buttons {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	font-family: helvetica-w01-bold, helvetica-w02-bold, helvetica-lt-w10-bold, sans-serif, !important;
}

.tab-buttons button {
	padding: 10px 20px;
	background-color: #d1d1d1;
	border: none;
	border-radius: 5px;
	margin-right: 5px;
	cursor: pointer;
	color: #ffffff;
}

.tab-buttons button.active {
	background-color: #8a8a8a;
	color: #ffffff;
}

.tab-content {
	border: 1px solid #ccc;
	padding: 10px;
	min-height: 200px;
	color: #ffffff;
}